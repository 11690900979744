import React from 'react';
import { saveAs } from 'file-saver';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Model as ModelType } from '../../API';
import Model from './Model';
import { getModelPoses } from '../../utils/poses';

export type ModelListProps = {
  models: ModelType[];
  removeFromModels: (modelId: string) => void;
  refreshModel: (model: ModelType) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomSpacing: {
      '& > *': {
        margin: theme.spacing(2, 0),
      },
    },
  })
);

const ModelList = ({ models, removeFromModels, refreshModel }: ModelListProps): JSX.Element => {
  const classes = useStyles();

  const handleDownloadPoses = async (projectId: string, modelId: string) => {
    saveAs(await getModelPoses(projectId, modelId), 'poses.json');
  };

  return (
    <section>
      <Typography component="h3" variant="h5" gutterBottom>
        Models
      </Typography>
      <div className={classes.bottomSpacing}>
        {models.map((model, index) => (
          <Model
            onDownload={handleDownloadPoses}
            onDelete={removeFromModels}
            onUpdate={refreshModel}
            key={`${model.pk}-${model.sk}`}
            index={index}
            model={model}
          />
        ))}
      </div>
    </section>
  );
};

export default ModelList;
