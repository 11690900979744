import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import PanToolIcon from '@material-ui/icons/PanTool';
import FaceIcon from '@material-ui/icons/Face';
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import { ProjectType as ProjectTypeEnum } from '../../API';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    type: {
      display: 'inline-block',
      position: 'relative',
      textTransform: 'capitalize',
      left: theme.spacing(1),
      bottom: theme.spacing(0.2),
    },
    lock: {
      marginRight: theme.spacing(1),
      verticalAlign: 'middle',
    },
  })
);

export type ProjectTypeProps = {
  type: ProjectTypeEnum;
  privateProject?: boolean;
  highlight?: boolean;
  className?: string;
  onClick?: () => void;
};

const ProjectType = ({
  type,
  privateProject,
  className,
  onClick,
  highlight,
}: ProjectTypeProps): JSX.Element => {
  const classes = useStyles();

  const getIcon = () => {
    switch (type) {
      case ProjectTypeEnum.Pose:
        return <AccessibilityIcon />;
      case ProjectTypeEnum.Hands:
        return <PanToolIcon />;
      case ProjectTypeEnum.Face:
        return <FaceIcon />;
      case ProjectTypeEnum.Holistic:
        return (
          <>
            <FaceIcon fontSize="small" />
            <AccessibilityIcon fontSize="small" />
            <PanToolIcon fontSize="small" />
          </>
        );
      default:
        return undefined;
    }
  };

  return (
    <div className={`${className} ${classes.type}`}>
      {privateProject && (
        <Tooltip className={classes.lock} title="Private Project">
          <LockIcon />
        </Tooltip>
      )}
      <Chip
        color={highlight ? 'primary' : 'default'}
        onClick={onClick}
        size="medium"
        icon={getIcon()}
        label={type}
      />
    </div>
  );
};

export default ProjectType;
