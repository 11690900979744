import React from 'react';
import { ProjectType } from '../../API';
import HolisticFrame from '../../utils/HolisticFrame';
import FrameListItem from './FrameListItem';

export type FrameGroupProps = {
  frames: HolisticFrame[];
  itemInRow: number;
  groupIndex: number;
  projectType: ProjectType;
  onRemove: (index: number) => void;
};

const FrameGroup = ({
  frames,
  itemInRow,
  groupIndex,
  onRemove,
  projectType,
}: FrameGroupProps): JSX.Element => {
  const startIndex = groupIndex * itemInRow;
  const endIndex = startIndex + itemInRow;

  const items = frames.slice(startIndex, endIndex);

  return (
    <>
      {items.map((frame, index) => (
        <FrameListItem
          key={JSON.stringify(frame)}
          frame={frame}
          onRemove={() => onRemove(startIndex + index)}
          projectType={projectType}
        />
      ))}
    </>
  );
};

export default FrameGroup;
