import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { green, red } from '@material-ui/core/colors';

import useHolistic from '../../hooks/useHolistic';

const HolisticStatus = (): JSX.Element => {
  const { isReady } = useHolistic();

  const { color, title } = isReady
    ? {
        color: green[500],
        title: 'Mediapipe ready',
      }
    : { color: red[500], title: 'Mediapipe not ready' };

  return (
    <Tooltip title={title}>
      <IconButton>
        <FiberManualRecordIcon style={{ color }} />
      </IconButton>
    </Tooltip>
  );
};

export default HolisticStatus;
