import React, { FC, createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Holistic, Options } from '@mediapipe/holistic';

const { REACT_APP_MEDIAPIPE_HOLISTIC_VERSION } = process.env;

const defaultOptions = {
  modelComplexity: 1,
  smoothLandmarks: true,
  minDetectionConfidence: 0.5,
  minTrackingConfidence: 0.5,
} as Options;

export type HolisticContextProps = {
  holistic: Holistic | undefined;
  isReady: boolean;
};
export type HolisticContextProviderProps = {
  children?: React.ReactNode;
  options?: Options;
};

export const HolisticContext = createContext<HolisticContextProps>({
  holistic: undefined,
  isReady: false,
});

export const HolisticContextProvider: FC<HolisticContextProviderProps> = ({
  children,
  options = defaultOptions,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [holistic, setHolistic] = useState<Holistic>();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    const holisticInstance = new Holistic({
      locateFile: (file: string) =>
        `https://cdn.jsdelivr.net/npm/@mediapipe/holistic@${REACT_APP_MEDIAPIPE_HOLISTIC_VERSION!}/${file}`,
    });

    setHolistic(holisticInstance);

    return () => {
      holistic?.close();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!holistic) return;

    (async () => {
      try {
        holistic.setOptions(options);
        await holistic.initialize();
        setIsReady(true);
      } catch (err: any) {
        enqueueSnackbar(err?.message || 'Holistic initialisation failed.', { variant: 'error' });
      }
    })();
    // eslint-disable-next-line
  }, [holistic]);

  return (
    <HolisticContext.Provider value={{ holistic, isReady }}>{children}</HolisticContext.Provider>
  );
};

export default () => useContext(HolisticContext);
