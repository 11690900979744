import React, { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { Auth } from 'aws-amplify';
import { AmplifyAuthenticator, AmplifySignIn, AmplifySignUp } from '@aws-amplify/ui-react';
import { makeStyles, Theme } from '@material-ui/core/styles';

const DISABLE_SIGN_UP = false;

const FORM_FIELDS = [
  { type: 'email', required: true },
  { type: 'password', required: true },
];

const useStyles = makeStyles((theme: Theme) => ({
  authenticator: {
    '--container-height': '',
    '--width': 'inherit',
    '--box-shadow': 0,
    '--amplify-primary-color': theme.palette.primary.main,
    '--amplify-primary-tint': theme.palette.primary.main,
    '--amplify-primary-shade': theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      '--padding': 0,
    },
  },
  ssoButton: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    borderRadius: 0,
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

const SignInDialog: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(true);

  const handleConfirm = () => {
    setOpen(false);
    Auth.federatedSignIn({ customProvider: 'SkySSO' });
  };
  return (
    <>
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpen(false);
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogContent>
          <Button
            className={classes.ssoButton}
            fullWidth
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            autoFocus
            disableFocusRipple
          >
            Sky Users Sign in with SkySSO
          </Button>
          <Divider className={classes.divider} />
          <AmplifyAuthenticator className={classes.authenticator} usernameAlias="email">
            <AmplifySignIn
              slot="sign-in"
              formFields={FORM_FIELDS}
              headerText="Sky Partners sign in below"
              hideSignUp={DISABLE_SIGN_UP}
              usernameAlias="email"
            >
              <div slot="federated-buttons" />
            </AmplifySignIn>
            <AmplifySignUp
              slot="sign-up"
              headerText="Sky Partners register below"
              formFields={FORM_FIELDS}
              usernameAlias="email"
            />
          </AmplifyAuthenticator>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default SignInDialog;
