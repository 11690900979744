import React from 'react';
import Typography from '@material-ui/core/Typography';
import ApiKeys from '../componets/user/ApiKeys';

type ProfilePageProps = {
  userEmail: string;
  userId: string;
};

const ProfilePage = ({ userEmail, userId }: ProfilePageProps) => (
  <>
    <Typography component="h1" variant="h3" gutterBottom>
      Profile
    </Typography>
    <Typography gutterBottom>Username: {userEmail}</Typography>
    <ApiKeys userId={userId} />
  </>
);

export default ProfilePage;
