import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { getErrorMessage } from '../utils/messages';
import ProjectType from '../componets/project/ProjectType';
import {
  Model,
  ProjectInput,
  useCreateTrainingMutation,
  useDeleteModelMutation,
  useDeleteProjectMutation,
  useUpdateProjectMutation,
  ProjectType as ProjectTypeEnum,
} from '../API';
import { LabelsContextProvider } from '../hooks/useLabels';
import TrainModel, { TrainData } from '../componets/model/TrainModel';
import ModelList from '../componets/model/ModelList';
import LabelList from '../componets/project/LabelList';
import ProjectActions from '../componets/project/ProjectActions';
import Loading from '../componets/common/Loading';
import useGetProjectQueryWithPoses from '../hooks/useGetProjectQueryWithPoses';
import { saveLabelPoses } from '../utils/poses';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      flexGrow: 1,
    },
    titleActions: {
      '& > *': {
        marginLeft: theme.spacing(2),
      },
    },
    sticky: {
      position: 'sticky',
      top: theme.spacing(2),
      maxHeight: `calc(100vh - ${theme.spacing(4)}px)`,
      overflow: 'auto',
      padding: theme.spacing(2),
      '& > *:not(:last-child)': {
        marginBottom: theme.spacing(4),
      },
    },
  })
);

type ProjectPageProps = {
  userEmail: string;
};

const ProjectPage = ({ userEmail }: ProjectPageProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { push } = useHistory();
  const { id: pk } = useParams<Record<string, string>>();

  const [models, setModels] = useState<Model[]>([]);
  const [sumOfFrames, setSumOfFrames] = useState<number>(0);
  const [countedFramesPerLabel, setCountedFramesPerLabel] = useState<{ [key: string]: number }>({});

  const { data, loading, error, refetch } = useGetProjectQueryWithPoses(pk);
  const [createTraining, { loading: isTrainingLoading }] = useCreateTrainingMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [deleteProject] = useDeleteProjectMutation();
  const [deleteModel] = useDeleteModelMutation();
  const {
    pk: projectId,
    name,
    version,
    labelsWithPoses = [],
    models: projectModels,
    userEmail: projectUserEmail,
  } = data?.getProject || {};

  const projectType = data?.getProject?.projectType ?? ProjectTypeEnum.Pose;
  const privateProject = data?.getProject?.private!;
  const MAX_SUM_OF_FRAMES: number = 10000;

  const isFrameLimitReached = sumOfFrames >= MAX_SUM_OF_FRAMES;

  const removeCountForRemovedLabel = useCallback(() => {
    const labelIdsBeforeDelete = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const [key] of Object.entries(countedFramesPerLabel)) {
      labelIdsBeforeDelete.push(key);
    }
    const labelIDsFromLabelsWithPoses = labelsWithPoses.map((label: any) => label.id);
    const deletedLabelFromProject = labelIdsBeforeDelete
      .filter((id) => !labelIDsFromLabelsWithPoses.includes(id))
      .toString();
    delete countedFramesPerLabel[deletedLabelFromProject];
  }, [countedFramesPerLabel, labelsWithPoses]);

  const saveFramesCountLocally = useCallback(() => {
    // eslint-disable-next-line no-restricted-syntax
    for (const label of labelsWithPoses) {
      if (label.poses) {
        countedFramesPerLabel[label.id] = JSON.parse(label.poses).length;
      } else {
        countedFramesPerLabel[label.id] = 0;
      }
    }
  }, [countedFramesPerLabel, labelsWithPoses]);

  const countAllRecordedFrames = useCallback(
    (updatedLabelId?: string, updatedLabelFrameCount?: number) => {
      let numberOfAllRecordedFrames = 0;

      if (updatedLabelId && updatedLabelFrameCount) {
        countedFramesPerLabel[updatedLabelId] = updatedLabelFrameCount;
      } else {
        if (labelsWithPoses.length < Object.entries(countedFramesPerLabel).length) {
          removeCountForRemovedLabel();
        }
        saveFramesCountLocally();
      }

      setCountedFramesPerLabel(countedFramesPerLabel);
      // eslint-disable-next-line no-restricted-syntax,guard-for-in
      for (const frames in countedFramesPerLabel) {
        numberOfAllRecordedFrames += countedFramesPerLabel[`${frames}`];
      }
      setSumOfFrames(numberOfAllRecordedFrames);
    },
    [
      countedFramesPerLabel,
      labelsWithPoses.length,
      saveFramesCountLocally,
      removeCountForRemovedLabel,
    ]
  );

  useEffect(() => {
    countAllRecordedFrames();
  }, [labelsWithPoses, countAllRecordedFrames]);

  useEffect(() => {
    if (projectModels?.length) {
      setModels(projectModels as Model[]);
    }
  }, [projectModels]);

  if (error) {
    const { message, variant }: any = getErrorMessage(error);
    enqueueSnackbar(message, { variant });
    push('/');
  }

  if (loading) return <Loading />;

  const handleError = (err: any) => {
    const { message, variant }: any = getErrorMessage(err);
    enqueueSnackbar(message, { variant });
  };

  const getEmptyLabels = () =>
    labelsWithPoses
      .filter((l: any) => !l.poses || !JSON.parse(l.poses)?.length)
      .map((l: any) => l.label);

  const handleTrainSubmit = async ({
    epochs,
    batchSize,
    learningRate,
    version,
    description,
  }: TrainData) => {
    const emptyLabels = getEmptyLabels();
    if (emptyLabels.length > 0) {
      const labels = emptyLabels.join(', ');
      enqueueSnackbar(
        `Labels ${labels} have no frames recorded. Record some frames or delete them.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    try {
      const response = await createTraining({
        variables: {
          input: {
            projectId,
            description,
            version,
            trainingParameters: {
              epochs,
              batchSize,
              learningRate,
            },
          },
        },
      });
      const model = (response?.data?.createTraining || {}) as Model;
      const { sk: trainingId, status } = model;

      setModels([model, ...models]);
      enqueueSnackbar(`Training id: ${trainingId}. Status: ${status}`, {
        variant: 'success',
      });
    } catch (err) {
      handleError(err);
    }
  };

  const handleLabelUpdate = async (labelId: string, label: string, input: string) => {
    try {
      await saveLabelPoses(pk, labelId, input);
      countAllRecordedFrames(labelId, JSON.parse(input).length);
      enqueueSnackbar(`Label "${label}" was updated`, { variant: 'success' });
    } catch (err) {
      handleError(err);
    }
  };

  const handleProjectAndRelatedModelsDelete = async () => {
    try {
      const promises = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const model of models) {
        const { sk } = model;
        promises.push(deleteModel({ variables: { pk, sk } }));
      }
      await Promise.all(promises);
      enqueueSnackbar(`All models for project ${pk} were deleted`, { variant: 'success' });
      await deleteProject({ variables: { pk } });
      enqueueSnackbar(`Project was deleted`, { variant: 'success' });
      push('/');
    } catch (err) {
      handleError(err);
    }
  };

  const handleProjectUpdate = async (input: ProjectInput) => {
    try {
      await updateProject({ variables: { pk, input: { ...input, version: version || 0 } } });
      await refetch();
      countAllRecordedFrames();
      enqueueSnackbar(`Project was updated`, { variant: 'success' });
    } catch (err) {
      handleError(err);
    }
  };

  const handleProjectPrivacyUpdate = async (makePrivate: boolean) => {
    handleProjectUpdate({ private: makePrivate });
  };

  const defaultLabels = labelsWithPoses.map(({ label, poses }: any) => ({
    name: label,
    options: {
      face: true,
      pose: true,
      leftHand: true,
      rightHand: true,
    },
    frames: poses ? JSON.parse(poses) : [],
  }));

  const removeFromModels = (modelId: string) => {
    setModels([...models].filter((model) => model.sk !== modelId));
  };

  const refreshModel = (newModel: Model) => {
    const index = models.findIndex((model: Model) => model.sk === newModel.sk);
    const newModels = [...models];
    newModels[index] = newModel;
    setModels(newModels);
  };

  return projectId ? (
    <LabelsContextProvider defaultLabels={defaultLabels} projectType={projectType!}>
      <Box display="flex" alignItems="center">
        <Typography className={classes.title} component="h1" variant="h3" gutterBottom>
          {name}
          <ProjectType type={projectType!} privateProject={privateProject} />
          <Typography variant="body2">Created By: {projectUserEmail}</Typography>
          <Typography variant="caption">
            Frames - Already recorded / Limit: {sumOfFrames} / {MAX_SUM_OF_FRAMES}
          </Typography>
        </Typography>
        <ProjectActions
          pk={pk}
          name={name!}
          userEmail={userEmail}
          privateProject={privateProject}
          onProjectPrivacyUpdate={handleProjectPrivacyUpdate}
          onProjectAndRelatedModelsDelete={handleProjectAndRelatedModelsDelete}
        />
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <LabelList
            projectType={projectType!}
            onLabelUpdate={handleLabelUpdate}
            onProjectUpdate={handleProjectUpdate}
            labels={labelsWithPoses}
            frameLimitReached={isFrameLimitReached}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.sticky}>
            <TrainModel
              models={models as Model[]}
              labels={defaultLabels}
              onSubmit={handleTrainSubmit}
              isLoading={isTrainingLoading}
            />
            {!!models.length && (
              <ModelList
                models={models as Model[]}
                removeFromModels={removeFromModels}
                refreshModel={refreshModel}
              />
            )}
          </Paper>
        </Grid>
      </Grid>
    </LabelsContextProvider>
  ) : (
    <Typography component="h3" variant="h5" gutterBottom>
      There is no project with provided id: <b>{pk}</b>
    </Typography>
  );
};

export default ProjectPage;
