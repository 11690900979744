import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ProjectListPage from './pages/ProjectListPage';
import ProjectPage from './pages/ProjectPage';
import ProfilePage from './pages/ProfilePage';
import Layout from './componets/layouts/Layout';
import { HolisticContextProvider } from './hooks/useHolistic';

type MainProps = {
  userEmail: string;
  userId: string;
};

const Main = ({ userEmail, userId }: MainProps) => (
  <Suspense fallback="Is Loading">
    <CssBaseline />
    <SnackbarProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      maxSnack={3}
    >
      <HolisticContextProvider>
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/">
                <ProjectListPage userEmail={userEmail} userId={userId} />
              </Route>
              <Route exact path="/project/:id">
                <ProjectPage userEmail={userEmail} />
              </Route>
              <Route exact path="/profile">
                <ProfilePage userEmail={userEmail} userId={userId} />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </HolisticContextProvider>
    </SnackbarProvider>
  </Suspense>
);

export default Main;
