import React, { useEffect, useState } from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Auth, Hub, Storage } from 'aws-amplify';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';

import Main from './Main';
import SignInDialog from './componets/common/SignInDialog';
import Loading from './componets/common/Loading';

const {
  REACT_APP_GRAPH_QL_API_URL,
  REACT_APP_REGION,
  REACT_APP_COGNITO_USER_POOL,
  REACT_APP_COGNITO_USER_POOL_CLIENT,
  REACT_APP_COGNITO_IDENTITY_POOL,
  REACT_APP_MODELS_BUCKET_NAME,
  REACT_APP_COGNITO_USER_POOL_DOMAIN,
  REACT_APP_REDIRECT_URL,
} = process.env;

Auth.configure({
  userPoolId: REACT_APP_COGNITO_USER_POOL,
  userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT,
  region: REACT_APP_REGION,
  identityPoolId: REACT_APP_COGNITO_IDENTITY_POOL,
  oauth: {
    domain: `${REACT_APP_COGNITO_USER_POOL_DOMAIN}.auth.eu-west-1.amazoncognito.com`,
    scope: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
    redirectSignIn: REACT_APP_REDIRECT_URL,
    redirectSignOut: REACT_APP_REDIRECT_URL,
    responseType: 'code',
    client_id: REACT_APP_COGNITO_USER_POOL_CLIENT,
  },
  federationTarget: 'COGNITO_USER_POOLS',
});

Storage.configure({
  AWSS3: {
    bucket: REACT_APP_MODELS_BUCKET_NAME,
    region: REACT_APP_REGION,
  },
});

const config = {
  url: REACT_APP_GRAPH_QL_API_URL!,
  region: REACT_APP_REGION!,
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
} as any;

const client = new ApolloClient({
  link: ApolloLink.from([createAuthLink(config), createSubscriptionHandshakeLink(config)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

type User = {
  email: string;
  id: string;
};

const App: React.FunctionComponent = () => {
  const [user, setUser] = useState<User | undefined>();
  const [token, setToken] = useState(null);
  const [showLogIn, setShowLogIn] = useState(false);

  function getToken() {
    return (
      Auth.currentSession()
        .then((session) => session)
        // eslint-disable-next-line no-console
        .catch((e) => console.log(e))
    );
  }

  const getUserInfo = async () => {
    await Auth.currentAuthenticatedUser()
      .then((result) =>
        setUser({
          email: result.attributes.email,
          id: result.attributes.sub,
        })
      )
      // eslint-disable-next-line no-console
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
          await getUserInfo();
          getToken().then((userToken: any) => setToken(userToken.idToken.jwtToken));
          break;
        case 'signOut':
        case 'oAuthSignOut':
          setUser(undefined);
          setToken(null);
          setShowLogIn(true);
          break;
        default:
          setShowLogIn(true);
          break;
      }
    });
    getUserInfo();
    getToken().then((userToken: any) => {
      if (!userToken) {
        setShowLogIn(true);
      }
      setToken(userToken);
    });
  }, []);

  if (user && token) {
    return (
      <ApolloProvider client={client}>
        <Main userEmail={user.email} userId={user.id} />
      </ApolloProvider>
    );
  }
  if (showLogIn) {
    return <SignInDialog />;
  }
  return <Loading />;
};
export default App;
