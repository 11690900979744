import React from 'react';
import { v4 as uuid } from 'uuid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import LabelListItem from './LabelListItem';
import { ProjectInput, ProjectType } from '../../API';
import AddNewLabel, { LabelFormData } from './AddNewLabel';

type LabelWithPoses = {
  id: string;
  label: string;
  poses?: string;
};

export type LabelListProps = {
  labels: LabelWithPoses[];
  projectType: ProjectType;
  onProjectUpdate: (input: ProjectInput) => void;
  onLabelUpdate: (labelId: string, label: string, input: string) => void;
  frameLimitReached: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labels: {
      '& > *': {
        marginBottom: theme.spacing(2),
      },
    },
  })
);

const LabelList = ({
  labels,
  projectType,
  onProjectUpdate,
  onLabelUpdate,
  frameLimitReached,
}: LabelListProps): JSX.Element => {
  const classes = useStyles();

  const simpleLabels = labels.map(({ id, label }) => ({ id, label }));

  const handleLabelDelete = (labelId: string) => {
    onProjectUpdate({
      labels: simpleLabels.filter((label) => label.id !== labelId),
    });
  };

  const handleLabelCreate = ({ label }: LabelFormData) => {
    onProjectUpdate({
      labels: [...simpleLabels, { label, id: uuid() }],
    });
  };

  return (
    <div className={classes.labels}>
      {labels.map(({ label, id }) => (
        <LabelListItem
          onDelete={handleLabelDelete}
          onUpdate={onLabelUpdate}
          key={id}
          id={id}
          label={label}
          projectType={projectType}
          frameLimitReached={frameLimitReached}
        />
      ))}
      <AddNewLabel onSubmit={handleLabelCreate} />
    </div>
  );
};

export default LabelList;
