import React, { FC, useState } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PrismHighlighter from '../common/PrismHighlighter';

export type ExportDialogProps = {
  websiteHtml: string;
  renderTrigger: (open: () => void) => JSX.Element;
};

const ExportDialog: FC<ExportDialogProps> = ({ renderTrigger, websiteHtml }: ExportDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    await navigator.clipboard.writeText(websiteHtml);
    enqueueSnackbar('Copied', { variant: 'success' });
  };

  const showDialog = (): void => {
    setOpen(true);
  };

  return (
    <>
      {renderTrigger(showDialog)}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">Model as HTML</DialogTitle>
        <DialogActions>
          <Button color="primary" onClick={handleClick}>
            Copy
          </Button>
        </DialogActions>
        <DialogContent>
          <PrismHighlighter language="html" content={websiteHtml} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ExportDialog;
