import { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useGetProjectQuery } from '../API';
import { getLabelPoses } from '../utils/poses';

type Label = {
  id: string;
  label: string;
};

type Response = {
  data: any;
  loading: boolean;
  error?: any;
  refetch: any;
};

const addPosesToLabel = async (projectId: string, label: Label) => {
  try {
    const Body = await getLabelPoses(projectId, label.id);
    const poses = await Body.text();
    return { ...label, poses };
  } catch (error) {
    return label;
  }
};

// Originally poses came back in the GraphQL Project Query
// Poses can be huge, so could exceed AppSync limits, so they were moved to S3
// This hook reconstructs the project query response as expected by the client code
function useGetProjectQueryWithPoses(projectId: string) {
  const [embellishedResponse, setEmbellishedResponse] = useState<Response>({
    data: undefined,
    loading: true,
    error: undefined,
    refetch: undefined,
  });

  const response = useGetProjectQuery({ variables: { pk: projectId } });

  const embellishLabelsWithPoses = useCallback(
    async (labels: Label[]) => {
      await Auth.currentUserCredentials();

      const promises = labels.map((label) => addPosesToLabel(projectId, label));
      const withPoses = await Promise.all(promises);

      const embellished = {
        ...response,
        loading: false,

        data: {
          ...response.data,
          getProject: { ...response.data?.getProject, labelsWithPoses: withPoses },
        },
      };

      setEmbellishedResponse(embellished);
    },
    [projectId, response]
  );

  useEffect(() => {
    if (response.loading) {
      return;
    }

    if (response.data?.getProject?.labels) {
      embellishLabelsWithPoses(response.data?.getProject?.labels);
    } else if (response.error) {
      setEmbellishedResponse(response);
    }
  }, [projectId, response, embellishLabelsWithPoses]);

  return embellishedResponse;
}

export default useGetProjectQueryWithPoses;
