import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
    },
    form: {
      display: 'flex',
      alignItems: 'baseline',
      justifyContent: 'space-between',
    },
  })
);

export type LabelFormData = {
  label: string;
};

export type AddNewLabelProps = {
  onSubmit: SubmitHandler<LabelFormData>;
};

const AddNewLabel = ({ onSubmit }: any): JSX.Element => {
  const classes = useStyles();

  const schema = yup.object().shape({
    label: yup.string().required(),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const getRegisterProps = (name: string) => {
    const { ref: inputRef, ...registerProps } = register(name);
    return {
      inputRef,
      ...registerProps,
      error: !!errors[name],
      helperText: errors[name]?.message,
      variant: 'standard',
    } as TextFieldProps;
  };

  return (
    <Paper className={classes.root}>
      <Typography component="h4" variant="h5" gutterBottom>
        Add New Label
      </Typography>
      <form
        className={classes.form}
        id="add-label-form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <TextField {...getRegisterProps('label')} label="New Label" required />
        <Button type="submit" variant="contained" color="primary">
          Add
        </Button>
      </form>
    </Paper>
  );
};

export default AddNewLabel;
