import React, { FC, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';

interface ConfirmDialogProps {
  title: string;
  message: string;
  actionLabel: string;
  onConfirm: () => void;
  renderTrigger: (open: () => void) => JSX.Element;
}

const ConfirmDialog: FC<ConfirmDialogProps> = ({
  renderTrigger,
  onConfirm,
  title,
  message,
  actionLabel,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  const showDialog = (): void => {
    setOpen(true);
  };

  return (
    <>
      {renderTrigger(showDialog)}
      <Dialog
        onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setOpen(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth="sm"
        aria-labelledby="confirmation-dialog-title"
        open={open}
        fullWidth
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button variant="contained" onClick={handleConfirm} color="secondary" autoFocus>
            {actionLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ConfirmDialog;
