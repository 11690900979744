import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import RotateRightIcon from '@material-ui/icons/RotateRight';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spin: {
      color: theme.palette.primary.main,
      fontSize: '50px',
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      animation: '$spin 1.2s linear infinite',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  })
);

const Loading: FC = () => {
  const classes = useStyles();

  return <RotateRightIcon className={classes.spin} />;
};
export default Loading;
