import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloneProjectDialog from './CloneProjectDialog';
import ConfirmDialog from '../common/ConfirmDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleActions: {
      position: 'relative',
      marginRight: theme.spacing(1),
    },
  })
);

const StyledMenuItem = withStyles((theme: Theme) => ({
  root: {
    padding: '0',
    '& .MuiButtonBase-root': {
      minWidth: '100%',
      justifyContent: 'left',
      padding: theme.spacing(1.5, 2),
    },
  },
}))(MenuItem);

type ProjectActionsProps = {
  pk: string;
  name: string;
  userEmail: string;
  privateProject: boolean;
  onProjectPrivacyUpdate: (privateProject: boolean) => void;
  onProjectAndRelatedModelsDelete: () => void;
};

const ProjectActions = ({
  pk,
  name,
  userEmail,
  privateProject,
  onProjectPrivacyUpdate,
  onProjectAndRelatedModelsDelete,
}: ProjectActionsProps) => {
  const classes = useStyles();

  const [showActionsEl, setShowActionsEl] = useState<null | HTMLElement>(null);
  const showActions = Boolean(showActionsEl);

  return (
    <div className={classes.titleActions}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => setShowActionsEl(e.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={showActionsEl}
        id="long-menu"
        open={showActions}
        onClose={() => setShowActionsEl(null)}
      >
        <StyledMenuItem>
          <ConfirmDialog
            title={`Make Project ${privateProject ? 'Public' : 'Private'}`}
            message=""
            actionLabel={`Make ${privateProject ? 'Public' : 'Private'}`}
            onConfirm={() => {
              onProjectPrivacyUpdate(!privateProject);
              setShowActionsEl(null);
            }}
            renderTrigger={(open: () => void) => (
              <Button
                startIcon={privateProject ? <LockOpenIcon /> : <LockIcon />}
                onClick={open}
                color="primary"
              >
                {`Make ${privateProject ? 'Public' : 'Private'}`}
              </Button>
            )}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <CloneProjectDialog
            projectId={pk}
            projectName={name!}
            userEmail={userEmail}
            privateProject={privateProject}
            onClose={() => setShowActionsEl(null)}
          />
        </StyledMenuItem>
        <StyledMenuItem>
          <ConfirmDialog
            title="Permanently delete project?"
            message='Deleting Project will also delete related models. To confirm deletion, click the "Delete" button.'
            actionLabel="Delete"
            onConfirm={() => {
              onProjectAndRelatedModelsDelete();
              setShowActionsEl(null);
            }}
            renderTrigger={(open: () => void) => (
              <Button startIcon={<DeleteIcon />} onClick={open} color="primary">
                Remove
              </Button>
            )}
          />
        </StyledMenuItem>
      </Menu>
    </div>
  );
};

export default ProjectActions;
