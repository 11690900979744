import { storageGet, storagePut } from './storage';

export const saveLabelPoses = (
  projectId: string,
  labelId: string,
  input: string
): Promise<Object> => storagePut(`${projectId}/poses/${labelId}.json`, input);

export const getModelPoses = (projectId: string, modelId: string): Promise<Blob> =>
  storageGet(`${projectId}/${modelId}/poses.json`);

export const getLabelPoses = (projectId: string, labelId: string): Promise<Blob> =>
  storageGet(`${projectId}/poses/${labelId}.json`);
