import React, { useEffect, useRef, CanvasHTMLAttributes } from 'react';
import draw from '../../utils/mediapipeDraw';
import HolisticFrame from '../../utils/HolisticFrame';
import { ProjectType } from '../../API';

export interface CanvasFrameProps extends CanvasHTMLAttributes<HTMLCanvasElement> {
  frame: HolisticFrame;
  height?: number | string;
  width?: number | string;
  projectType: ProjectType;
}
const CanvasFrame = ({
  frame,
  height = 160,
  width = 160,
  projectType,
  ...props
}: CanvasFrameProps): JSX.Element => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const { current } = canvasRef || {};
    if (!current) return;

    draw(frame, projectType, current);
  }, [frame, projectType]);

  return <canvas ref={canvasRef} width={height} height={width} {...props} />;
};

export default CanvasFrame;
