import React, { SyntheticEvent, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VideocamIcon from '@material-ui/icons/Videocam';
import PublishIcon from '@material-ui/icons/Publish';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import BlazeposeWrapper from './BlazeposeWrapper';
import BlazeposeUpload from './BlazeposeUpload';
import BlazeposeCam from './BlazeposeCam';
import FrameList from './FrameList';
import HolisticFrame from '../../utils/HolisticFrame';
import { ProjectType } from '../../API';
import useLabels from '../../hooks/useLabels';
import ConfirmDialog from '../common/ConfirmDialog';
import defaultLabelData from '../../config/defaultLabelData';

export type LabelListItemProps = {
  id: string;
  label: string;
  projectType: ProjectType;
  onUpdate: (labelId: string, label: string, input: string) => void;
  onDelete: (labelId: string) => void;
  frameLimitReached: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      padding: theme.spacing(2),
    },
    body: {
      padding: theme.spacing(2),
    },
    root: {
      display: 'relative',
    },
    actions: {
      marginTop: theme.spacing(2),
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
  })
);

const LabelListItem = ({
  id,
  label,
  projectType,
  onUpdate,
  onDelete,
  frameLimitReached,
}: LabelListItemProps): JSX.Element => {
  const classes = useStyles();
  const { addLabel, getLabel } = useLabels();
  const [view, setView] = useState<string>('frames');

  const labelObject = getLabel(label);
  const frames = labelObject?.frames || [];

  const updateFrames = (newFrames: HolisticFrame[]) => {
    const newFramesWithoutImage = newFrames.map(
      ({ POSE_LANDMARKS, FACE_LANDMARKS, LEFT_HAND_LANDMARKS, RIGHT_HAND_LANDMARKS }) => ({
        POSE_LANDMARKS,
        FACE_LANDMARKS,
        LEFT_HAND_LANDMARKS,
        RIGHT_HAND_LANDMARKS,
      })
    );
    addLabel({ name: label, frames: newFrames });
    onUpdate(id, label, JSON.stringify(newFramesWithoutImage));
  };

  const handleSave = (_: SyntheticEvent<HTMLButtonElement>, newFrames: HolisticFrame[]) => {
    const input = [...newFrames, ...frames] as HolisticFrame[];
    updateFrames(input);
  };

  const handleFrameClear = () => {
    updateFrames([]);
  };

  const getView = () => {
    switch (view) {
      case 'record':
        return (
          <BlazeposeWrapper
            BlazePoseProcessor={BlazeposeCam}
            onBack={() => setView('frames')}
            onSave={handleSave}
            title="Webcam"
            projectType={projectType}
          />
        );
      case 'upload':
        return (
          <BlazeposeWrapper
            BlazePoseProcessor={BlazeposeUpload}
            onBack={() => setView('frames')}
            onSave={handleSave}
            title="Upload"
            emptyText="No frames to display please upload a video."
            projectType={projectType}
          />
        );
      default:
        return (
          <>
            <FrameList projectType={projectType} onRemove={updateFrames} frames={frames} />
            {view === 'frames' && (
              <div className={classes.actions}>
                <Button
                  startIcon={<VideocamIcon />}
                  variant="contained"
                  color="primary"
                  onClick={() => setView('record')}
                  disabled={frameLimitReached}
                >
                  Record
                </Button>
                <Button
                  startIcon={<PublishIcon />}
                  variant="contained"
                  color="secondary"
                  // component="label"
                  onClick={() => setView('upload')}
                  disabled={frameLimitReached}
                >
                  Upload
                  <input type="file" hidden />
                </Button>
                <ConfirmDialog
                  title="Clear all frames?"
                  message='To confirm deletion, click the "Delete" button.'
                  actionLabel="Delete"
                  renderTrigger={(open: () => void) => (
                    <Button
                      onClick={open}
                      disabled={!frames.length}
                      variant="outlined"
                      startIcon={<ClearAllIcon />}
                    >
                      Clear All
                    </Button>
                  )}
                  onConfirm={handleFrameClear}
                />
                <ConfirmDialog
                  title="Permanently delete label?"
                  message='To confirm deletion, click the "Delete" button.'
                  actionLabel="Delete"
                  renderTrigger={(open: () => void) => (
                    <IconButton onClick={open} aria-label="delete">
                      <DeleteIcon />
                    </IconButton>
                  )}
                  onConfirm={() => onDelete(id)}
                />
              </div>
            )}
          </>
        );
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography title={id} component="h3" variant="h4">
          {label}
        </Typography>
        {label === defaultLabelData.label && (
          <Typography variant="body2">{defaultLabelData.description}</Typography>
        )}
      </div>
      <Divider />
      <div className={classes.body}>{getView()}</div>
    </Paper>
  );
};

export default LabelListItem;
