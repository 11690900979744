import React, { MouseEventHandler } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CanvasFrame from './CanvasFrame';
import HolisticFrame from '../../utils/HolisticFrame';
import { ProjectType } from '../../API';

export type FrameListItemProps = {
  frame: HolisticFrame;
  projectType: ProjectType;
  onRemove: MouseEventHandler<HTMLButtonElement>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      position: 'relative',
      '&:hover .MuiIconButton-root': {
        opacity: 1,
      },
      padding: theme.spacing(0.5),
    },
    canvas: {
      width: '100%',
      height: '100%',
      borderColor: theme.palette.grey[300],
      borderWidth: 1,
      borderStyle: 'solid',
      borderRadius: '4px',
    },
    remove: {
      position: 'absolute',
      opacity: 0,
      transition: 'opacity .4s',
      top: 0,
      right: 0,
    },
  })
);

const FrameListItem = ({ projectType, frame, onRemove }: FrameListItemProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <CanvasFrame className={classes.canvas} frame={frame} projectType={projectType} />
      <IconButton
        disabled={!onRemove}
        onClick={onRemove}
        className={classes.remove}
        aria-label="delete"
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

export default FrameListItem;
