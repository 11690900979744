import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  YAxis,
  Label,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

type GraphData = {
  xAxisValue: number;
  [key: string]: number | undefined;
};

type LineType = {
  title: string;
  dataKey: string;
  color: string;
};

interface GraphProps {
  title: string;
  data: GraphData[];
  yTitle: string;
  xTitle: string;
  lines: LineType[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    root: {
      margin: theme.spacing(4, 0),
    },
  })
);

const Graph = ({ title, yTitle, xTitle, data, lines }: GraphProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.title} component="h2" variant="h5" gutterBottom align="center">
        {title}
      </Typography>
      <ResponsiveContainer width="95%" height={400}>
        <LineChart width={500} height={400} data={data} margin={{ bottom: 50 }}>
          <XAxis dataKey="xAxisValue">
            <Label value={xTitle} position="bottom" />
          </XAxis>
          <YAxis label={{ value: yTitle, angle: -90, position: 'insideLeft' }} />
          <Tooltip formatter={(value: number) => value.toFixed(4)} />
          <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
          {lines.map((line) => (
            <Line
              key={line.title}
              name={line.title}
              type="monotone"
              dataKey={line.dataKey}
              stroke={line.color}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
export default Graph;
