export interface DefaultLabelData {
  label: string;
  warning: string;
  description: string;
}

const defaultCategoryName = 'None';

const defaultLabelData: DefaultLabelData = {
  label: defaultCategoryName,
  warning: `Project does not have a label for training ${defaultCategoryName}.`,
  description: `The ${defaultCategoryName} label has been added to your project by default. Without training ${defaultCategoryName}, the model may identify an incorrect label when there is no other suitable match.`,
};

export default defaultLabelData;
