import { Storage } from 'aws-amplify';

export const storagePut = (key: string, contents: string): Promise<Object> =>
  Storage.put(key, contents, {
    customPrefix: {
      public: '',
    },
  });

export const storageGet = async (key: string): Promise<Blob> => {
  const { Body } = (await Storage.get(key, {
    download: true,
    cacheControl: 'no-cache',
    customPrefix: {
      public: '',
    },
  })) as any;
  return Body;
};
