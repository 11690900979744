import { POSE_CONNECTIONS, FACEMESH_TESSELATION, HAND_CONNECTIONS } from '@mediapipe/holistic';
import { drawConnectors, drawLandmarks } from '@mediapipe/drawing_utils';
import HolisticFrame from './HolisticFrame';
import { ProjectType } from '../API';

const draw = (
  holisticFrame: HolisticFrame,
  projectType: ProjectType,
  canvas: HTMLCanvasElement
) => {
  const ctx = canvas.getContext('2d')!;

  ctx.fillStyle = 'white';
  ctx.fill();
  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.strokeStyle = 'transparent';

  ctx.rect(1, 1, canvas.width - 1, canvas.height - 1);
  ctx.stroke();

  if (!holisticFrame) return;

  const thickLine = canvas.width / 166;
  const mediumLine = canvas.width / 200;
  const thinLine = canvas.width / 600;

  if (holisticFrame.POSE_LANDMARKS) {
    drawConnectors(ctx, holisticFrame.POSE_LANDMARKS, POSE_CONNECTIONS, {
      color: '#0072c9',
      lineWidth: mediumLine,
    });

    if (projectType !== ProjectType.Hands && projectType !== ProjectType.Holistic) {
      drawLandmarks(ctx, holisticFrame.POSE_LANDMARKS, {
        color: '#0072c9',
        fillColor: '#73add8',
        radius: thickLine,
      });
    }
  }

  if (holisticFrame.FACE_LANDMARKS) {
    drawConnectors(ctx, holisticFrame.FACE_LANDMARKS, FACEMESH_TESSELATION, {
      color: '#0072c9',
      lineWidth: thinLine,
    });
  }

  if (holisticFrame.LEFT_HAND_LANDMARKS) {
    drawConnectors(ctx, holisticFrame.LEFT_HAND_LANDMARKS, HAND_CONNECTIONS, {
      color: '#FF0000',
      lineWidth: mediumLine,
    });
    drawLandmarks(ctx, holisticFrame.LEFT_HAND_LANDMARKS, {
      color: '#FF0000',
      fillColor: '#00FF00',
      radius: thickLine,
    });
  }

  if (holisticFrame.RIGHT_HAND_LANDMARKS) {
    drawConnectors(ctx, holisticFrame.RIGHT_HAND_LANDMARKS, HAND_CONNECTIONS, {
      color: '#00FF00',
      lineWidth: mediumLine,
    });
    drawLandmarks(ctx, holisticFrame.RIGHT_HAND_LANDMARKS, {
      color: '#00FF00',
      fillColor: '#FF0000',
      radius: thickLine,
    });
  }
};

export default draw;
