import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import { getErrorMessage } from '../../utils/messages';
import { toLocalFormattedString } from '../../utils/dateTransform';
import { useGetApiKeyQuery, useCreateApiKeyMutation, useDeleteApiKeyMutation } from '../../API';
import Loading from '../common/Loading';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2, 0),
    },
    key: {
      margin: theme.spacing(1, 0),
    },
    chip: {
      marginLeft: theme.spacing(2),
    },
  })
);

type ApiKeysProps = {
  userId: string;
};

const ApiKeys = ({ userId }: ApiKeysProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [showKey, setShowKey] = useState<boolean>(false);
  const {
    data: getApiKeyData,
    loading: getApiKeyLoading,
    error: getApiKeyError,
    fetchMore,
  } = useGetApiKeyQuery({ variables: { userId } });
  const [createApiKey] = useCreateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();

  if (getApiKeyError) {
    const { message, variant }: any = getErrorMessage(getApiKeyError);
    enqueueSnackbar(message, { variant });
  }

  if (getApiKeyLoading) return <Loading />;

  const onCreateApiKey = async () => {
    try {
      const response = await createApiKey({
        variables: {
          userId,
        },
      });
      if (!response.errors) {
        enqueueSnackbar('Api Key Created', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Api Key failed to create', {
          variant: 'error',
        });
      }
      fetchMore({
        variables: {
          userId,
        },
      });
    } catch (err) {
      const { message, variant }: any = getErrorMessage(err);
      enqueueSnackbar(message, { variant });
    }
  };

  const onDeleteApiKey = async () => {
    try {
      const response = await deleteApiKey({
        variables: {
          userId,
        },
      });
      if (!response.errors) {
        enqueueSnackbar('Api Key Deleted', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar('Api Key failed to delete', {
          variant: 'error',
        });
      }
      fetchMore({
        variables: {
          userId,
        },
      });
    } catch (err) {
      const { message, variant }: any = getErrorMessage(err);
      enqueueSnackbar(message, { variant });
    }
  };

  const apiKey = getApiKeyData?.getApiKey;

  return (
    <div className={classes.root}>
      {apiKey ? (
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom component="h2">
              Api Key
              <Chip
                className={classes.chip}
                size="small"
                color={apiKey!.enabled ? 'primary' : 'secondary'}
                icon={apiKey!.enabled ? <CheckIcon /> : <ClearIcon />}
                label={apiKey!.enabled ? 'Enabled' : 'Disabled'}
              />
            </Typography>
            <div className={classes.key}>
              {showKey ? (
                <Typography variant="h5" component="h2">
                  {apiKey!.value}{' '}
                  <Button
                    startIcon={<VisibilityOffIcon />}
                    color="primary"
                    onClick={() => setShowKey(false)}
                    variant="outlined"
                  >
                    Hide Key
                  </Button>
                </Typography>
              ) : (
                <Button
                  startIcon={<VisibilityIcon />}
                  color="primary"
                  onClick={() => setShowKey(true)}
                  variant="outlined"
                >
                  Show Key
                </Button>
              )}
            </div>
            <Typography variant="body2" component="p">
              Created: {toLocalFormattedString(apiKey!.createdDate!)}
            </Typography>
          </CardContent>
          <CardActions>
            <Button startIcon={<DeleteIcon />} color="primary" onClick={() => onDeleteApiKey()}>
              Delete Api Key
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          color="primary"
          onClick={() => onCreateApiKey()}
        >
          Create Api Key
        </Button>
      )}
    </div>
  );
};

export default ApiKeys;
