import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';

import 'prismjs/themes/prism-tomorrow.css';

export type PrismHighlighterProps = {
  language: string;
  content: string;
};

const PrismHighlighter = ({ language, content }: PrismHighlighterProps) => {
  const codeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!codeRef) return;
    Prism.highlightElement(codeRef.current!);
  }, [content]);

  return (
    <pre>
      <code ref={codeRef} className={`language-${language}`}>
        {content}
      </code>
    </pre>
  );
};
export default PrismHighlighter;
