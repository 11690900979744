import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ProjectType from '../project/ProjectType';

import { ProjectType as ProjectTypeEnum } from '../../API';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1),
    },
  })
);

type ProjectListPageProps = {
  filters: string[];
  setFilters: (filters: string[]) => void;
};

const projectTypes = [
  ProjectTypeEnum.Pose,
  ProjectTypeEnum.Hands,
  ProjectTypeEnum.Face,
  // ProjectTypeEnum.Holistic,
];

const ProjectListPage = ({ filters, setFilters }: ProjectListPageProps) => {
  const classes = useStyles();

  return (
    <>
      {projectTypes.map((projectType) => (
        <ProjectType
          className={classes.chip}
          key={projectType}
          type={projectType}
          highlight={filters.includes(projectType)}
          onClick={() => {
            const index = filters.indexOf(projectType);
            const newFilters = [...filters];
            if (index > -1) {
              newFilters.splice(index, 1);
            } else {
              newFilters.push(projectType);
            }
            setFilters(newFilters);
          }}
        />
      ))}
    </>
  );
};

export default ProjectListPage;
