import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import SettingsIcon from '@material-ui/icons/Settings';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CamSettings, { CamSettingsData } from './CamSettings';
import useLocalStorage from '../../hooks/useLocalStorage';

export type BlazeposeCamPros = {
  captureSnapshot: (video: HTMLVideoElement) => void;
};

type CaptureState = 'IDLE' | 'HOLDING' | 'RECORDING' | 'WAITING';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    backdrop: {
      height: '100%',
      width: '100%',
      overflow: 'auto',
    },
    bottomPannel: {
      minHeight: 50,
      height: '10%',
    },
    overlay: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar,
      bottom: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(255,255,255, .8)',
    },
    recordInfo: {
      position: 'absolute',
      zIndex: theme.zIndex.appBar,
      top: 0,
      left: 0,
      padding: theme.spacing(2),
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  })
);

type CamSettingsType = {
  fps: number;
  duration: number;
  delay: number;
  device: string;
};

const defaultCamSettings: CamSettingsType = { fps: 10, duration: 2, delay: 3, device: '' };

const BlazeposeCam = ({ captureSnapshot }: BlazeposeCamPros): JSX.Element => {
  const classes = useStyles();

  const webcamRef = useRef<Webcam>(null);
  const [isCamReady, setIsCamReady] = useState<boolean>(false);
  const [isSettingEnabled, setIsSettingEnabled] = useState<boolean>(false);
  const [settings, setSettings] = useLocalStorage<CamSettingsType>(
    'camsettings',
    defaultCamSettings
  );
  const [captureState, setCaptureState] = React.useState<CaptureState>('IDLE');
  const [counter, setCounter] = useState<number>(0);

  const isRecordingOrWaiting = captureState === 'WAITING' || captureState === 'RECORDING';

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: 'user',
  };

  const { delay, duration, fps, device } = settings;

  const handleSettingsUpdate = (settings: CamSettingsData) => {
    setIsSettingEnabled(false);
    setSettings(settings);
  };

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    let nextState: CaptureState = 'IDLE';
    let count = 0;

    if (captureState === 'WAITING') {
      count = delay;
      nextState = 'RECORDING';
    }

    if (captureState === 'RECORDING') {
      count = duration;
      nextState = 'IDLE';
    }

    if (isRecordingOrWaiting) {
      setCounter(count);
      interval = setInterval(() => {
        count -= 1;
        if (!count) {
          setCaptureState(nextState);
        } else {
          setCounter(count);
        }
      }, 1000);
    }

    return (): void => {
      if (interval) {
        clearInterval(interval);
        setCounter(0);
      }
    };
    // eslint-disable-next-line
  }, [captureState, setCounter]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (captureState === 'RECORDING') {
      const period = 1000 / fps;

      interval = setInterval(() => {
        captureSnapshot(webcamRef.current?.video!);
      }, period);
    }
    return (): void => {
      if (interval) {
        clearInterval(interval);
      }
    };
    // eslint-disable-next-line
  }, [captureState]);

  useEffect(() => {
    if (isCamReady) {
      captureSnapshot(webcamRef.current?.video!);
    }
    // eslint-disable-next-line
  }, [isCamReady]);

  return (
    <div className={classes.root}>
      {isRecordingOrWaiting && (
        <div className={classes.recordInfo}>
          <Typography variant="h3" color="error">
            {counter}
          </Typography>
          {captureState === 'RECORDING' && <FiberManualRecordIcon color="error" />}
        </div>
      )}
      <Webcam
        width="100%"
        audio={false}
        ref={webcamRef}
        videoConstraints={{ ...videoConstraints, deviceId: device }}
        onLoadedData={() => setIsCamReady(true)}
      />
      {isSettingEnabled && (
        <div className={clsx(classes.overlay, classes.backdrop)}>
          <CamSettings defaultValues={{ ...settings, device }} onSubmit={handleSettingsUpdate} />
        </div>
      )}
      {isCamReady && !isSettingEnabled && !isRecordingOrWaiting && (
        <div className={clsx(classes.overlay, classes.bottomPannel)}>
          <div>
            <IconButton
              onClick={() => captureSnapshot(webcamRef.current?.video!)}
              aria-label="take snapshot"
              color="primary"
            >
              <PhotoCameraIcon />
            </IconButton>
            <IconButton
              onClick={() => setCaptureState('WAITING')}
              aria-label="record"
              color="primary"
            >
              <FiberManualRecordIcon color="error" />
            </IconButton>
            <IconButton
              onClick={() => setIsSettingEnabled(true)}
              aria-label="settings"
              color="primary"
            >
              <SettingsIcon />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlazeposeCam;
