import React, { useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, Controller, useForm } from 'react-hook-form';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';

export type CamSettingsData = {
  fps: number;
  delay: number;
  duration: number;
  device?: string;
};

export type CamSettingsProps = {
  defaultValues?: CamSettingsData;
  onSubmit: SubmitHandler<CamSettingsData>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      height: '100%',
      overflow: 'auto',
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

const CamSettings = ({
  onSubmit,
  defaultValues = { fps: 10, duration: 2, delay: 1, device: '' },
}: CamSettingsProps) => {
  const classes = useStyles();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);

  const schema = yup.object().shape({
    fps: yup.number().required(),
    delay: yup.number().required(),
    duration: yup.number().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    defaultValues,
  });

  const handleDevices = useCallback(
    (mediaDevices: MediaDeviceInfo[]) => {
      setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput'));
    },
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  useEffect(() => {
    if (!devices.length) return;
    setValue('device', getValues('device') || devices[0].deviceId || '');
  }, [devices, getValues, setValue]);

  const getRegisterProps = (name: 'fps' | 'duration' | 'delay') => {
    const { ref: inputRef, ...registerProps } = register(name);
    return {
      inputRef,
      ...registerProps,
      error: !!errors[name],
      helperText: errors[name]?.message,
      variant: 'standard',
      fullWidth: true,
      margin: 'normal',
      inputProps: {
        min: 0,
      },
      type: 'number',
    } as TextFieldProps;
  };

  return (
    <section className={classes.root}>
      <form id="cam-settings-form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <FormControl fullWidth>
          <InputLabel htmlFor="device">Device</InputLabel>
          <Controller
            control={control}
            name="device"
            render={({ field: { ref, value } }) => (
              <Select
                onChange={(evt) => setValue('device', evt.target.value as string)}
                value={value || ''}
                labelId="device"
                inputRef={ref}
              >
                {devices.map(({ label, deviceId }) => (
                  <MenuItem key={deviceId} value={deviceId}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>

        <TextField {...getRegisterProps('fps')} label="FPS" required />
        <TextField {...getRegisterProps('delay')} label="Delay" required />
        <TextField {...getRegisterProps('duration')} label="Duration" required />
        <Fab
          type="submit"
          className={classes.fab}
          size="small"
          disabled={!isValid}
          color="primary"
          aria-label="save"
        >
          <SaveIcon />
        </Fab>
      </form>
    </section>
  );
};

export default CamSettings;
